import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { ThemeProvider } from "@aws-amplify/ui-react";
import Layout from "./components/Layout";
import { Authenticator } from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";
import formFields from "./components/FormFields";
import components from "./components/AuthComponents";
import AmplifyAuthStyles from "./components/AmplifyAuthStyles";
import { Amplify } from "aws-amplify";

Amplify.configure(awsExports);

// Lazy load nos componentes da página, visando impedir acesso indesejado ao nosso código fonte
const Dashboard = lazy(() => import("./pages/dashboard"));
const Reports = lazy(() => import("./pages/reports"));
const Clients = lazy(() => import("./pages/clients"));
const Credits = lazy(() => import("./pages/credits"));
const Logout = lazy(() => import("./pages/logout"));
const DeviceState = lazy(() => import("./pages/device_state"));

// Componente de página não encontrada
const NoMatch = () => {
  return (
    <div>
      <h2>Página não encontrada!</h2>
      <p>
        <Link to="/">Voltar para a página inicial</Link>
      </p>
    </div>
  );
};

export default function App() {
  return (
    <div>
      <style>{AmplifyAuthStyles}</style>
      <Authenticator
        formFields={formFields}
        components={components}
        hideSignUp={true}
      >
        {({ signOut }) => (
          <div className="App">
            <ThemeProvider>
              <Router>
                <Suspense fallback={""}>
                  <Routes>
                    <Route path="/" element={<Layout />}>
                      <Route index element={<Dashboard />} />
                      <Route path="reports" element={<Reports />} />
                      <Route path="clients" element={<Clients />} />
                      <Route path="credits" element={<Credits />} />
                      <Route path="device_state" element={<DeviceState />} />
                      <Route path="logout" element={<Logout />} />
                      <Route path="*" element={<NoMatch />} />
                    </Route>
                  </Routes>
                </Suspense>
              </Router>
            </ThemeProvider>
          </div>
        )}
      </Authenticator>
    </div>
  );
}
