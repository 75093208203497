import React from 'react';
import { createRoot } from 'react-dom/client'; 
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import aws_exports from './aws-exports';

Amplify.configure(aws_exports);

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
