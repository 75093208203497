import React from 'react';
import {
  useTheme,
  Heading,
  Text,
  View,
  Image,
  Button,
  useAuthenticator,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          title="Liconic Logo"
          alt="Logo da Liconic"
          description=""
          src="/liconic_logo.webp"
          style={{ width: '50%' }}
          loading='lazy'
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; Liconic. Todos os direitos reservados.
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Iniciar sessão
        </Heading>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
          >
            Esqueci a minha senha
          </Button>
        </View>
      );
    },
  },

  SetupTotp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          {/* Enter Information: */}
        </Heading>
      );
    },
    // Footer() {
    //   // Caso queria inserir algo Abaixo do link Voltar
    //   return <Text>Footer Information</Text>;
    // },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          {/* Enter Information: */}
        </Heading>
      );
    },
    // Footer() {
    //   return <Text>Footer Information</Text>;
    // },
  },
  ForgotPassword: {
    Header() {
      // Descomentar caso for usar o Footer Information
      // const { tokens } = useTheme();
      return <Heading level={3}>Recuperar senha</Heading>;
    },
    Footer() {
      // return <Text>Footer Information</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        ></Heading>
      );
    },
    Footer() {
      // return <Text>Footer Information</Text>;
    },
  },
};

export default components;
