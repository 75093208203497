import React from "react";
import { Icon } from "@aws-amplify/ui-react";

import {
  MdDashboard,
  MdPeopleAlt,
  MdReport,
  MdLogout,
  MdCo2,
  MdEnergySavingsLeaf,
} from "react-icons/md";

export const baseConfig = {
  projectLink: "/", // GitHub link in the navbar
  docsRepositoryBase: "https://liconic.github.io/api-doc/", // base URL for the docs repository
  titleSuffix: "Liconic",
  search: true,
  header: true,
  headerText: "",
  footer: true,
  footerText: (
    <>
      <span>© Liconic Technology {new Date().getFullYear()}</span>
    </>
  ),

  logo: (
    <>
      <img
        loading="lazy"
        src={process.env.PUBLIC_URL + "./logotipo.webp"}
        alt="logo"
        width="700"
        height="220"
      />
    </>
  ),
};

/// Navigation sidebar
export const appNavs = [
  {
    eventKey: "dashboard",
    icon: <Icon as={MdDashboard} />,
    title: "Dashboard",
    to: "/",
  },

  {
    eventKey: "reports",
    icon: <Icon as={MdReport} />,
    title: "Relatórios",
    to: "/reports",
  },
  {
    eventKey: "clients",
    icon: <Icon as={MdPeopleAlt} />,
    title: "Clientes",
    to: "/clients",
  },
  {
    eventKey: "device_state",
    icon: <Icon as={MdEnergySavingsLeaf} />,
    title: "Estado dos Geradores",
    to: "/device_state",
  },
  {
    eventKey: "credits",
    icon: <Icon as={MdCo2} />,
    title: "Créditos de Carbono",
    to: "/credits",
  },
  {
    eventKey: "logout",
    icon: <Icon as={MdLogout} />,
    title: "Sair",
    to: "/logout",
  },
];
