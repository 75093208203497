import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('pt');

const formFields = I18n.putVocabulariesForLanguage('pt', {
  'Sign In': 'Entrar', 
  'Sign in': 'Entrar', 
  Email: 'E-mail', 
  Password: 'Senha', 
  'Enter your email': 'Digite o seu e-mail',
  'Enter your Password': 'Digite a sua senha',
  'Reset my password': 'Recuperar senha',
  'Back to Sign In': 'Retornar à página de login',
  'Incorrect username or password.':
    'Oops! Endereço de e-mail ou senha incorretos. Por favor, tente novamente.',
  'User does not exist.': 'Usuário não existe.',
  'Username/client id combination not found.':
    'Endereço de e-mail não cadastrado.',
  'Code *': 'Código',
  'New Password': 'Nova senha',
  'Please confirm your Password': 'Por favor, confirme a sua nova senha',
  'Change Password': 'Alterar senha',
});

export default formFields;
