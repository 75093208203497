import React from "react";
import { Button } from "@aws-amplify/ui-react";
import { MdMenu, MdMenuOpen } from "react-icons/md";

const SidebarNavToggle = ({ expand, onChange }) => {
  return (
    <>
      <Button
        id="botao-nav"
        size="small"
        variation="menu"
        onClick={onChange}
        className={expand ? "expanded" : ""}
        fontSize="20px"
        height="40px"
      >
        {expand ? <MdMenuOpen style={{ color: "white" }} /> : <MdMenu style={{ color: "white" }} />}
      </Button>
    </>
  );
};

export default SidebarNavToggle;
